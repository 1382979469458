const PATH_PARAM_REGEX = RegExp(':[a-zA-Z]+', 'g');

/**
 * Parse path params
 * Example: /api/path/:param1/sub-path/:param2 -> ['param1', 'param2']
 */
const parsePathParams = (url: string) => {
  const params = [];
  const keys = [];
  let match: ReturnType<RegExp['exec']>;
  while ((match = PATH_PARAM_REGEX.exec(url)) !== null) {
    // [:xxx, ...]
    params.push(match[0]);
    keys.push(match[0].replace(':', ''));
  }

  return { params, keys };
};

/**
 * Support path params
 * Example: url: /path/:id/:someThing
 * the params should be: {id: '123', someThing: 'ahihi'}
 */
export const transformPathParam = (request) => {
  const { params, keys } = parsePathParams(request.url);
  params.forEach((param, idx) => {
    const { [keys[idx]]: value, ...rest } = request.params;

    request.url = request.url.replace(param, value);
    // mean: Remove the key (avoid using delete keyword)
    // Example: keys array has ['id', 'type']
    // request.params is {page: 0, size: 10, id: '1234', type: 'ahihi'}
    // Because we extract the `id`/`type` above
    // this action will remove `id`/`type` out of url
    request.params = rest;
  });
};
