import { cloneDeep, findLast, get, isEqual, isNil, set } from 'lodash';
import * as qs from 'qs';
import { customAlphabet } from 'nanoid';

const isBrowser = () => typeof window !== 'undefined';

export const escapeCSS = (value) => {
  if (value) {
    return ''.replace.call(value, /(^[^_a-zA-Z\u00a0-\uffff]|[^-_a-zA-Z0-9\u00a0-\uffff])/g, '$1');
  }
  return value;
};

export const generateUniqueCssClass = () => customAlphabet('abcdefghijklmnopqrstuvwxyz-_', 10)();

/* eslint-disable no-console */
export const isString = (arg: any) => {
  return typeof arg === 'string';
};

export const isObject = (arg: any) => {
  return arg && typeof arg === 'object';
};

export const hexToRgb = (hex: string) => {
  return hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
};

export const toStringQuery = (object: any) => {
  return qs.stringify(object, { arrayFormat: 'indices', skipNulls: true, encode: false });
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getParagraphKey = (index, paragraphKeys) => {
  if (!paragraphKeys) {
    return index;
  }
  const pIdx = paragraphKeys.find((key) => key === index);
  if (pIdx !== undefined) {
    return pIdx;
  }
  const bigger = paragraphKeys.find((key) => key > index);
  if (bigger !== undefined) {
    return bigger;
  }
  return findLast(paragraphKeys, (key) => key < index);
};

export const base64StringToBlobUrl = async (base64String: string) => {
  if (isBrowser()) {
    const blob = await fetch(base64String).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }
  return null;
};

export const generateTableColumn = (isDefaultView, storeFilter, view, defaultColumn) => {
  let sort;
  if (isDefaultView) {
    sort = storeFilter?.sort;
  } else {
    sort = view?.filter?.sort;
  }
  if (sort) {
    const [colName, sortDirection] = sort.split(',');
    const col = cloneDeep(defaultColumn);
    col.forEach((item) => {
      if (item.dataIndex === colName) {
        item.defaultSortOrder = sortDirection === 'asc' ? 'ascend' : 'descend';
      }
    });
    return col;
  }
  return defaultColumn;
};

const omitBy = (obj, check) => {
  obj = { ...obj };
  Object.entries(obj).forEach(([key, value]) => check(value) && delete obj[key]);
  return obj;
};

export const isDefaultValue = (object, defaultVl) => {
  const { blogContentGroup, ...rest } = object;
  const compareObj = omitBy(rest, isNil);

  return isEqual(compareObj, defaultVl);
};

export const genEmptyPagable = ({ size }) => ({
  content: [],
  pageable: {
    sort: { unsorted: false, sorted: true, empty: false },
    pageNumber: 0,
    pageSize: size,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  totalPages: 0,
  last: true,
  totalElements: 0,
  first: true,
  sort: { unsorted: false, sorted: true, empty: false },
  numberOfElements: 0,
  size,
  number: 0,
  empty: true,
});

export const stringifyEditorFields = (data, fieldNames) => {
  fieldNames.forEach((fieldName) => {
    const dataField = get(data, fieldName);
    if (dataField) {
      set(
        data,
        fieldName,
        dataField instanceof Array
          ? dataField.map((item) => {
              if (typeof item.content !== 'string') {
                return {
                  ...item,
                  content: JSON.stringify(item.content),
                };
              }
              return item;
            })
          : {
              ...dataField,
              content:
                typeof dataField.content === 'string'
                  ? dataField.content
                  : JSON.stringify(dataField.content),
            }
      );
    }
  });
};

export const isBytesExceedMb = (bytes, mbLimit) => {
  return bytes / (1024 * 1024) > mbLimit;
};

export const countFilterByFields = (filterObj, fields = []) => {
  let count = 0;
  fields.forEach((field) => {
    const subFields = field.split(',');
    const haveValue = subFields.some((f) => {
      const fieldName = f.trim();
      return filterObj[fieldName] && filterObj[fieldName] !== 'ALL';
    });
    if (haveValue) {
      count += 1;
    }
  });
  return count;
};

export const countFilterAllPrimitivesByFields = (filterObj, fields = []) => {
  let count = 0;
  fields.forEach((field) => {
    const subFields = field.split(',');
    const haveValue = subFields.some((f) => {
      const fieldName = f.trim();
      return !isNil(filterObj[fieldName]) && filterObj[fieldName] !== 'ALL';
    });
    if (haveValue) {
      count += 1;
    }
  });
  return count;
};

export const arrayToMobxMap = (arr: any[], keyName: string, override = (value) => value) => {
  return arr.reduce((prevValue, currentValue) => {
    return {
      ...prevValue,
      [currentValue[keyName]]: override(currentValue),
    };
  }, {});
};
export const convertArrayToHashMap = (arr, key) => {
  // input: [{count: 1, name: "aa"}, {count: 2, name: "bb"}]
  // output: {aa: { count: 1 }, bb: { count: 2 }}
  return arr.reduce((acc, cur) => {
    acc[cur[key]] = cur;
    return acc;
  }, {});
};

export const replaceArrItemByIndex = ({ arr, atIndex, itemValue }) => {
  return arr.map((item, index) => {
    if (index === atIndex) {
      return itemValue;
    }
    return item;
  });
};

export const isJson = (str?: string) => {
  let result = true;
  if (str === null) return false;

  try {
    JSON.parse(str);
  } catch (ex) {
    result = false;
  }

  return result;
};

export const sortTimeRangeAndTrackPosition = ({
  timeRanges,
  myCurrentIndex,
  timeRangeProperties = ['start', 'end'],
}) => {
  const sortedRanges = [...timeRanges].sort(
    (a, b) => a?.[timeRangeProperties[0]] - b?.[timeRangeProperties[0]]
  );
  const currentIndex = sortedRanges.findIndex(
    (range) =>
      range?.[timeRangeProperties[0]] === timeRanges[myCurrentIndex]?.[timeRangeProperties[0]] &&
      range?.[timeRangeProperties[1]] === timeRanges[myCurrentIndex]?.[timeRangeProperties[1]]
  );
  return {
    sortedArrays: sortedRanges,
    newIndex: currentIndex === -1 ? myCurrentIndex : currentIndex,
  };
};

export const hasCollapsedTimeRanges = ({
  timeRanges,
  timeRangeProperties = ['start', 'end'],
  myCurrentIndex,
}) => {
  const currentRange = timeRanges[myCurrentIndex];

  const conflictingRanges = timeRanges.filter((range, index) => {
    if (index === myCurrentIndex) {
      return false; // Exclude the current range
    }

    return (
      currentRange[timeRangeProperties[1]] > range[timeRangeProperties[0]] &&
      currentRange[timeRangeProperties[0]] < range[timeRangeProperties[1]]
    );
  });

  const hasConflicts = conflictingRanges.length > 0;

  return {
    hasConflicts,
    conflictingRanges,
  };
};

export const countFieldsWithValues = (obj) => {
  let count = 0;

  for (const key in obj) {
    if (obj[key]) {
      count++;
    }
  }

  return count;
};
