/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { useRouter } from 'next/router';
import { useGTMTracking } from './useGTMTracking';

const GTMPageContext = React.createContext<any>(null);

export const withGTMPageContext =
  ({ showMessengerButton = true }) =>
  (Component: any) =>
  (props: any) => {
    const pageContext: any = React.useMemo(() => {
      const urlInfo = props?.pageProps?.urlInfo;
      if (urlInfo) {
        return {
          pageType: urlInfo.type,
          testType: urlInfo.testType,
          skill: urlInfo.skill,
        };
      }
      return {};
    }, [props?.pageProps?.urlInfo]);

    const router = useRouter();
    const { setVariable } = useGTMTracking();

    React.useEffect(() => {
      function setGTMPageVariable() {
        setVariable(pageContext);
      }

      setGTMPageVariable();

      router.events.on('routeChangeComplete', setGTMPageVariable);

      return () => {
        router.events.off('routeChangeComplete', setGTMPageVariable);
      };
    }, [pageContext, router.events, setVariable]);
    return (
      <GTMPageContext.Provider value={pageContext}>
        <Component {...props} />
      </GTMPageContext.Provider>
    );
  };
export const useGTMPageContext = () => React.useContext(GTMPageContext);
