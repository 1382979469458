import { InternalPageLink } from '@doltech/routeDefinition';
import { PageTypeEnum } from '@doltech/utils/lib/constants';
import { types } from 'mobx-state-tree';

import { LandingSidebarItemModel } from './landing-sidebar-item.store';

export const LandingSharedDataStore = types
  .model('LandingSharedDataStore', {
    initialed: types.optional(types.boolean, false),
    menuItems: types.array(LandingSidebarItemModel),
    internalPageLink: types.maybeNull(types.string),
    pageTypeGroup: types.maybeNull(
      types.enumeration<any>('PageTypeEnum', Object.values(PageTypeEnum))
    ),
  })
  .views((self) => ({
    get pageLink() {
      return JSON.parse(self.internalPageLink) as InternalPageLink;
    },
  }))
  .actions((self) => ({
    getHomePageLink() {
      const homepageLink = self.pageLink?.staticPageLink?.LANDING_HOME?.url;
      return homepageLink || '';
    },
    getDailyLink() {
      const dailyLink = self.pageLink?.staticPageLink?.LANDING_DAILY_LEARNING_NEWS?.url;
      return dailyLink || '';
    },
    getReadingPracticeLandingLink() {
      const readingLandingUrl = self.pageLink?.staticPageLink?.LANDING_READING_PRACTICE_TEST?.url;
      return readingLandingUrl || '';
    },
    getListeningPracticeLandingLink() {
      const listeningLandingUrl =
        self.pageLink?.staticPageLink?.LANDING_LISTENING_PRACTICE_TEST?.url;
      return listeningLandingUrl || '';
    },
    getIELTSFullTestLandingLink() {
      const listeningLandingUrl = self.pageLink?.staticPageLink?.LANDING_FULL_TEST?.url;
      return listeningLandingUrl || '';
    },
  }))
  .views((self) => ({
    getNavigationItem(pageType) {
      const sidebarItems = self.menuItems;
      return sidebarItems.find((item) => pageType === item.pageType);
    },
    getPageToNavigate(type: PageTypeEnum) {
      const sidebarItems = self.menuItems;
      const menu = sidebarItems.find((item) => type === item.pageType);
      return menu?.url;
    },
  }));
