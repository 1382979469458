import * as React from 'react';
import styled from 'styled-components';

export const renderNode = (Component, content, defaultProps) => {
  if (content == null || content === false) {
    return null;
  }
  if (React.isValidElement(content)) {
    return content;
  }
  if (typeof content === 'function') {
    return content(defaultProps);
  }
  if (content instanceof Array) {
    return content;
  }
  // Just in case
  if (content === true) {
    return <Component {...defaultProps} />;
  }
  if (typeof content === 'string' || typeof content === 'number') {
    return <Component {...defaultProps}>{content}</Component>;
  }
  return <Component {...defaultProps} {...content} />;
};

export const Span = styled.span``;
export const Div = styled.div``;
export const Paragraph = styled.p``;

export default renderNode;
