import * as React from 'react';
import { SpokeLoading } from '../auth/SpokeLoading';
// Old way using external libs
// import withHydrationOnDemand from 'react-hydration-on-demand';
// const withDolHydrationOnDemand = (options = {}) => {
//   return withHydrationOnDemand(options);
// };
export const isBrowser = () => typeof window !== 'undefined';

const withDolHydrationOnDemand = (options = {}) => {
  return (WrappedComponent) => (props) => {
    return (
      <React.Suspense fallback={<SpokeLoading />}>
        <WrappedComponent {...props} />
      </React.Suspense>
    );
  };
};

interface WithDynamicBlogEditorPluginHydrationOptions {
  canInteract?: boolean;
}

export const withDynamicBlogEditorPluginHydration = (
  options?: WithDynamicBlogEditorPluginHydrationOptions
) => {
  return (WrappedComponent) => (props) => {
    WrappedComponent.name = 'WithDynamicBlogEditorPluginHydration';

    if (options?.canInteract) {
      return (
        <React.Suspense fallback={<SpokeLoading />}>
          <WrappedComponent {...props} />
        </React.Suspense>
      );
    }
    return <WrappedComponent {...props} />;
  };
};

export default withDolHydrationOnDemand;
