import { PageTypeEnum } from '@doltech/utils/lib/constants';
import { Instance, types } from 'mobx-state-tree';

export const LandingSidebarItemModel = types.model('LandingSidebarItemModel', {
  pageType: types.enumeration<any>(Object.values(PageTypeEnum)),
  url: types.string,
  title: types.maybe(types.string),
  isStatic: types.optional(types.boolean, false),
});

export interface LandingSidebarItemModelInstance extends Instance<typeof LandingSidebarItemModel> {}
