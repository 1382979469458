/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Instance, SnapshotIn, types as t } from 'mobx-state-tree';
import { useCreateStore, useProvider, useStore } from 'mobx-store-provider';
import styled from 'styled-components';
import { AudioPlayerStateEnum } from '@doltech/utils/lib/constants';

const SUPPORTED_MIME_TYPES = [
  'audio/ogg',
  'audio/x-m4a',
  'audio/mpeg',
];

interface AudioPlayOptions {
  /** play back speed for audio */
  speed?: number;

  /* seek to 0 when click play */
  resetOnPlay?: boolean;

  /** audio current time in seconds */
  currentTimeInSeconds?: number;
}

export const ControlledAudioPlayInBackgroundStore = t
  .model('DolEditorStore', {
    url: t.maybeNull(t.string),
    state: t.optional(t.string, AudioPlayerStateEnum.NOT_STARTED),
    audioTimeInSeconds: t.optional(t.number, 0),
    durationInSeconds: t.optional(t.number, 0),
    playing: t.optional(t.boolean, false),
    playbackRate: t.optional(t.number, 1),
    volume: t.optional(t.number, 1),
    muted: t.optional(t.boolean, false),
    loop: t.optional(t.boolean, false),
    readyToPlayThrough: t.optional(t.boolean, false),
    loading: t.optional(t.boolean, false),
    loopTimes: t.optional(t.number, 2),
    playedTime: t.optional(t.number, 0),
  })
  .volatile(() => ({
    audioRef: null,
  }))
  .actions((self) => ({
    registerUrl(url, options: AudioPlayOptions = {}) {
      self.url = url;

      for (const eachSourceEl of self.audioRef.children) {
        eachSourceEl.src = url;
      }

      self.audioRef.load();
      self.audioRef.currentTime = options?.currentTimeInSeconds || 0;
      self.audioRef.playbackRate = options?.speed || self.playbackRate;
      self.playing = false;
    },
    play(timeInSeconds?: number) {
      if (timeInSeconds) {
        self.audioRef.currentTime = timeInSeconds;
      }
      self.audioRef.play();
    },
    pause() {
      self.audioRef.pause();
    },
    resume() {
      self.audioRef.play();
    },
    stop() {
      self.audioRef.currentTime = self.durationInSeconds;
    },
    backward(timeInSeconds = 10) {
      self.audioRef.currentTime = self.audioRef.currentTime - timeInSeconds;
    },
    forward(timeInSeconds = 10) {
      self.audioRef.currentTime = self.audioRef.currentTime + timeInSeconds;
    },
    seek(timeInSeconds?: number) {
      if (Number.isFinite(timeInSeconds)) {
        self.audioRef.currentTime = timeInSeconds;
      }
    },
    changeVolume(volume) {
      self.audioRef.volume = volume;
    },
    changePlaybackRate(playbackRate) {
      self.audioRef.playbackRate = playbackRate;
    },
    toggleMute() {
      self.muted = !self.audioRef.muted;
      self.audioRef.muted = !self.audioRef.muted;
    },
    reset() {
      self.audioRef.currentTime = 0;
    },
  }))
  .actions((self) => ({
    playByUrl(url, options: AudioPlayOptions = {}) {
      self.registerUrl(url, options);
      self.play();
    },
    setAudioTimeInSeconds(audioTimeInSeconds) {
      self.audioTimeInSeconds = audioTimeInSeconds;
    },
    setDurationInSeconds(durationInSeconds) {
      self.durationInSeconds = durationInSeconds;
    },
    setVolume(volume) {
      self.volume = volume;
    },
    setPlaybackRate(playbackRate) {
      self.playbackRate = playbackRate;
    },
    setAudioRef(audioRef) {
      self.audioRef = audioRef;
    },
    setState(state) {
      self.state = state;
    },
    setPlaying(playing) {
      self.playing = playing;
    },
    toggleLoop() {
      self.loop = !self.loop;
    },
    setReadyToPlayThrough(isReady) {
      self.readyToPlayThrough = isReady;
    },
    setLoading(loading) {
      self.loading = loading;
    },
    setLoopTimes() {
      // case 999999 is forever
      if (self.loopTimes === 999999) {
        self.loopTimes = 0;
      } else if (self.loopTimes === 2) {
        self.loopTimes = 999999;
      } else {
        self.loopTimes = self.loopTimes + 1;
      }
      self.playedTime = 0;
    },
    setPlayedTime(times) {
      self.playedTime = times;
    },
  }));

export const useControlledAudioPlayInBackgroundStore = () => {
  return useStore(ControlledAudioPlayInBackgroundStore);
};

const BackgroundAudioPlayerMain = styled.div`
  display: none;
`;

const BackgroundAudioPlayer = observer(() => {
  const store = useControlledAudioPlayInBackgroundStore();

  return (
    <BackgroundAudioPlayerMain>
      <audio
        ref={(ref) => store.setAudioRef(ref)}
        playsInline
        onPlaying={() => {
          store.setState(AudioPlayerStateEnum.PLAYING);
          store.setPlaying(true);
        }}
        onPause={() => {
          store.setState(AudioPlayerStateEnum.PAUSED);
          store.setPlaying(false);
        }}
        onDurationChange={(event: any) => store.setDurationInSeconds(event.target.duration)}
        onTimeUpdate={(event: any) => store.setAudioTimeInSeconds(event.target.currentTime)}
        onVolumeChange={(event: any) => store.setVolume(event.target.volume)}
        onRateChange={(event: any) => store.setPlaybackRate(event.target.playbackRate)}
        onEnded={() => store.setState(AudioPlayerStateEnum.NOT_STARTED)}
        onLoadStart={() => store.setLoading(true)}
        onCanPlayThrough={() => {
          store.setLoading(false);
          store.setReadyToPlayThrough(true);
        }}
      >
        {SUPPORTED_MIME_TYPES.map((mimeType, typeIndex) => {
          return <source key={typeIndex} id={`audio-source-${typeIndex + 1}`} src="" type={mimeType}></source>;
        })}
        Your browser does not support the audio format.
      </audio>
    </BackgroundAudioPlayerMain>
  );
});

export const withControlledAudioPlayInBackground = (Component: any) => (props: any) => {
  const audioPlayInBackgroundCreateStore = useCreateStore(ControlledAudioPlayInBackgroundStore, {});
  const AudioPlayInBackgroundProvider = useProvider(ControlledAudioPlayInBackgroundStore);

  return (
    <AudioPlayInBackgroundProvider value={audioPlayInBackgroundCreateStore}>
      <BackgroundAudioPlayer />
      <Component {...props} />
    </AudioPlayInBackgroundProvider>
  );
};

export interface ControlledAudioPlayInBackgroundStoreSnapshotIn
  extends SnapshotIn<typeof ControlledAudioPlayInBackgroundStore> {}
export interface ControlledAudioPlayInBackgroundStoreInstance
  extends Instance<typeof ControlledAudioPlayInBackgroundStore> {}
