import { InternalPageLink } from '@doltech/routeDefinition';
import { PageTypeEnum, STATIC_PAGE_TYPES } from '@doltech/utils/lib/constants';
import { useStore } from 'mobx-store-provider';

import { LandingSharedDataStore } from './landing-shared-data.store';
import { LandingSidebarItemModelInstance } from './landing-sidebar-item.store';

interface GetLandingSidebarStoreParams {
  internalPageLink?: InternalPageLink;
  pageTypeGroup?: PageTypeEnum;
}

export const getLandingSidebarInitialState = ({
  internalPageLink,
  pageTypeGroup,
}: GetLandingSidebarStoreParams) => {
  if (internalPageLink?.staticPageLink) {
    const menuItems = STATIC_PAGE_TYPES.filter((pageType) => {
      const pageData = internalPageLink.staticPageLink[pageType];
      return Boolean(pageData?.type && pageData?.url);
    })
      .map((pageType) => {
        const { type, url } = internalPageLink.staticPageLink[pageType];
        return {
          pageType: type,
          url,
        } as LandingSidebarItemModelInstance;
      })
      .filter(Boolean);

    return {
      initialed: true,
      menuItems,
      internalPageLink: JSON.stringify(internalPageLink),
      pageTypeGroup,
    };
  }
  return {};
};

export const useLandingSharedDataStore = () => {
  return useStore(LandingSharedDataStore);
};
