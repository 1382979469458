import { AuthConfig } from './interface/auth.interface';
import { environment } from '../environment/environment';
import { FireBaseAuthManager } from './class/FireBaseAuthManager';
import { TokenAuthManager } from './class/TokenAuthManager';
import { setAuthManager, setCurrentAppName } from './authManager';
import { withRefreshTokenOnExpired } from './class/jwt/refresh-token/withRefreshTokenOnExpired';
import { withRefreshTokenSynchronized } from './class/jwt/refresh-token/withRefreshTokenSynchronized';
import { withAuthStateReady } from './class/jwt/refresh-token/withAuthStateReady';

const compose = (...funcs) =>
  funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
    (arg) => arg
  );

const withHOCs = compose(
  withRefreshTokenOnExpired,
  withRefreshTokenSynchronized,
  withAuthStateReady
);

export const bootstrapAuthManager = (
  app:
    | 'onlineTest'
    | 'landing'
    | 'courseExercise'
    | 'courseExerciseV2'
    | 'lms'
    | 'lmsStudent'
    | 'landing-blog'
    | 'landing-blog-detail'
    | 'landing-dictionary'
    | 'landing-grammar'
    | 'entranceTest'
    | 'entranceTestStudent'
    | 'audioToText'
    | 'emailTemplate'
    | 'mid-final-test'
    | 'final-test-student'
    | 'lms-ai-mock-test'
    | 'lms-course-management'
    | 'lms-grammar'
    | 'lms-teacher'
    | 'lms-toeic'
    | 'lms-eccs',
  configs: AuthConfig = {}
) => {
  setCurrentAppName(app);
  const sharedAuth0Config = {
    apiKey: environment.REACT_APP_AUTH_CLIENT_ID,
    authDomain: environment.REACT_APP_AUTH_DOMAIN,
    projectId: environment.REACT_APP_AUTH_PROJECT_ID,
    dataBaseUrl: environment.REACT_APP_AUTH_REAL_TIME_DB_URL,
    ...configs,
  };

  if (!app) {
    throw new Error('`app` is required');
  }
  let authManager;

  switch (app) {
    case 'onlineTest':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_ONLINE_TEST_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_ONLINE_TEST_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;
    case 'landing-dictionary':
    case 'landing-blog-detail':
    case 'landing-blog':
      authManager = new FireBaseAuthManager({
        auth: {
          ...sharedAuth0Config,
          redirectUrl: environment.REACT_APP_AUTH_DOL_VN_REDIRECT_URL,
          logOutRedirectUrl: environment.REACT_APP_AUTH_DOL_VN_LOGOUT_REDIRECT_URL,
          inAppBrowserRedirectUrl: environment.REACT_APP_AUTH_DOL_VN_IN_APP_BROWSER_REDIRECT_URL,
          authDomain: environment.REACT_APP_AUTH_DOL_VN_DOMAIN,
          languageCode: 'en', // trick handle client app
        },
        localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
      });
      break;
    case 'landing-grammar':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            redirectUrl: environment.REACT_APP_AUTH_GRAMMAR_REDIRECT_URL,
            logOutRedirectUrl: environment.REACT_APP_AUTH_GRAMMAR_LOGOUT_REDIRECT_URL,
            inAppBrowserRedirectUrl: environment.REACT_APP_AUTH_GRAMMAR_IN_APP_BROWSER_REDIRECT_URL,
            authDomain: environment.REACT_APP_AUTH_GRAMMAR_DOMAIN,
            languageCode: 'en', // trick handle client app
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;
    case 'landing':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            redirectUrl: environment.REACT_APP_AUTH_DOL_VN_REDIRECT_URL,
            logOutRedirectUrl: environment.REACT_APP_AUTH_DOL_VN_LOGOUT_REDIRECT_URL,
            inAppBrowserRedirectUrl: environment.REACT_APP_AUTH_DOL_VN_IN_APP_BROWSER_REDIRECT_URL,
            authDomain: environment.REACT_APP_AUTH_DOL_VN_DOMAIN,
            languageCode: 'en', // trick handle client app
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    case 'courseExercise':
      authManager = new TokenAuthManager();
      break;

    case 'courseExerciseV2':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_EXERCISE_V2_STUDENT_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_EXERCISE_V2_STUDENT_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    case 'lms':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_LMS_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_LMS_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    case 'lmsStudent':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_LMS_STUDENT_AUTH_LOGOUT_REDIRECT_URL,
            authDomain: environment.REACT_APP_AUTH_LMS_STUDENT_DOMAIN,
            redirectUrl: environment.REACT_APP_LMS_STUDENT_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    case 'entranceTest':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_ENTRANCE_TEST_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_ENTRANCE_TEST_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    case 'entranceTestStudent':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_ENTRANCE_TEST_STUDENT_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_ENTRANCE_TEST_STUDENT_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;
    case 'emailTemplate':
    case 'audioToText':
      authManager = new FireBaseAuthManager({
        auth: {
          ...sharedAuth0Config,
        },
        localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
      });
      break;
    case 'mid-final-test':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_FINAL_TEST_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_FINAL_TEST_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;
    case 'final-test-student':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_FINAL_TEST_STUDENT_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_FINAL_TEST_STUDENT_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;
    case 'lms-ai-mock-test':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_LMS_AI_MOCK_TEST_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_LMS_AI_MOCK_TEST_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    case 'lms-course-management':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_LMS_AI_MOCK_TEST_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_LMS_AI_MOCK_TEST_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    case 'lms-grammar':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_LMS_GRAMMAR_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_LMS_GRAMMAR_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    case 'lms-teacher':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_LMS_TEACHER_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_LMS_TEACHER_AUTH_REDIRECT_URL,
            inAppBrowserRedirectUrl:
              environment.REACT_APP_LMS_TEACHER_AUTH_IN_APP_BROWSER_REDIRECT_URL,
            authDomain: environment.REACT_APP_AUTH_LMS_TEACHER_DOMAIN,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    case 'lms-toeic':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_LMS_TOEIC_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_LMS_TOEIC_AUTH_REDIRECT_URL,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    case 'lms-eccs':
      authManager = withHOCs(
        new FireBaseAuthManager({
          auth: {
            ...sharedAuth0Config,
            logOutRedirectUrl: environment.REACT_APP_LMS_ECCS_AUTH_LOGOUT_REDIRECT_URL,
            redirectUrl: environment.REACT_APP_LMS_ECCS_AUTH_REDIRECT_URL,
            inAppBrowserRedirectUrl:
              environment.REACT_APP_LMS_ECCS_AUTH_IN_APP_BROWSER_REDIRECT_URL,
            authDomain: environment.REACT_APP_AUTH_LMS_ECCS_DOMAIN,
          },
          localStorageKey: environment.REACT_AUTH_LOCAL_STORAGE_KEY,
        })
      );
      break;

    default:
      break;
  }

  setAuthManager(authManager);
};
