/* eslint-disable react/destructuring-assignment */
import * as React from 'react';

export const withEncryptedData = (Component) => (props) => {
  React.useEffect(() => {
    return () => {
      console.info('Unmount withEncryptedData');
    };
  }, []);
  if (props && props.pageProps && props.pageProps.encryptedData) {
    const data = JSON.parse(decodeURIComponent(props.pageProps.encryptedData));
    const tmpProps = {
      ...props,
      pageProps: data,
    };
    return <Component {...tmpProps} />;
  }

  if (props && props.encryptedData) {
    const data = JSON.parse(decodeURIComponent(props.encryptedData));
    return <Component {...data} />;
  }

  return <Component {...props} />;
};
