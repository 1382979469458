import * as React from 'react';
import { Portal } from '@reach/portal';
import styled from 'styled-components';
import { LAYER } from '@doltech/utils/lib/constants';

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${LAYER.LAYER5};
`;

interface ModalPortalFullScreenProps {
  children?: React.ReactNode;
}

export const ModalPortalFullScreen = (props: ModalPortalFullScreenProps) => {
  const { children } = props;

  return (
    <Portal>
      <Container className="modal-portal-full-screen">{children}</Container>
    </Portal>
  );
};
