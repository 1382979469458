import * as React from 'react';

interface GoogleTagManager {
  push: (data: any) => void;
}

const useDataLayer = () => {
  const getTracker = React.useCallback(() => {
    const wi = window as any;
    if (wi && !wi.dataLayer) {
      wi.dataLayer = [];
    }

    if (wi && wi.dataLayer) {
      return wi.dataLayer as GoogleTagManager;
    }

    return {
      push: (data) => {},
    } as GoogleTagManager;
  }, []);

  return { getTracker };
};

export const useGTMTracking = () => {
  const { getTracker } = useDataLayer();

  const trackEvent = React.useCallback(
    (event: string, data?: any) => {
      getTracker().push({
        event,
        ...data,
      });
    },
    [getTracker]
  );

  const setVariable = React.useCallback(
    (data?: any) => {
      getTracker().push({
        ...data,
      });
    },
    [getTracker]
  );

  return { trackEvent, setVariable };
};
