import { Router } from 'next/router';
import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
// import dynamic from 'next/dynamic';
//
// const ReactQueryDevtools = dynamic<any>(
//   () => import('react-query/devtools').then((mod) => mod.ReactQueryDevtools),
//   { ssr: false }
// );
//
// const devToolWrapperStyles: React.CSSProperties = {
//   position: 'fixed',
//   zIndex: 9000,
// };

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const withReactQueryConfig = (Component: any) => (props: any) => {
  React.useEffect(() => {
    const clearMutationCacheOnRouting = () => {
      queryClient.getMutationCache().clear();
    };
    Router.events.on('routeChangeComplete', clearMutationCacheOnRouting);
    return () => {
      Router.events.off('routeChangeComplete', clearMutationCacheOnRouting);
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      {/* {process.env.NODE_ENV === 'development' && ( */}
      {/*  <div style={devToolWrapperStyles}> */}
      {/*    <ReactQueryDevtools initialIsOpen={false} /> */}
      {/*  </div> */}
      {/* )} */}
      <Component {...props} />
    </QueryClientProvider>
  );
};

export { withReactQueryConfig };
