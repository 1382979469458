import numeral from 'numeral';

export function initNumeralConfig() {
  if (!numeral.locales.vi) {
    numeral.register('locale', 'vi', {
      delimiters: {
        thousands: '.',
        decimal: '.',
      },
      abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T',
      },
      currency: {
        symbol: ' VNĐ',
      },
    });
  }

  numeral.locale('vi');
}
