import * as React from 'react';
import { Router } from 'next/router';
import { observer } from 'mobx-react-lite';
import { useMount } from 'react-use';
import { Instance, types as t } from 'mobx-state-tree';
import { useCreateStore, useProvider, useStore } from 'mobx-store-provider';
import { ModalPortalFullScreen } from '../figma/Modal/ModalPortal/ModalPortalFullScreen';

export interface GlobalModalPortalContent {
  content: React.ReactNode;
}

export const GlobalModalPortalStore = t
  .model('GlobalModalPortalStore', {
    visible: t.optional(t.boolean, false),
  })
  .volatile(() => ({
    currentModalContent: null as GlobalModalPortalContent,
  }))
  .actions((self) => ({
    show(modalContent: GlobalModalPortalContent) {
      self.visible = true;
      self.currentModalContent = modalContent;
    },
    hide() {
      self.visible = false;
    },
    hideAndDestroy() {
      self.visible = false;
      self.currentModalContent = null;
    },
  }));

export const useGlobalModalPortalStore = () => {
  return useStore(GlobalModalPortalStore);
};

export const useCloseGlobalPortalWhenEsc = () => {
  const { hide } = useGlobalModalPortalStore();

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'Esc':
        case 'Escape':
          hide();
          break;
        default:
      }
    };

    document.addEventListener('keydown', handleKeyDown, { passive: true });

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [hide]);
};

export const useCloseGlobalPortalWhenClickOverlay = () => {
  const { hide } = useGlobalModalPortalStore();

  React.useEffect(() => {
    const elm = document.querySelector('#scroll-content__overlay');

    const handleClick = () => {
      hide();
    };

    elm.addEventListener('click', handleClick, { passive: true });

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [hide]);
};

const ModalComponent = observer(() => {
  const store = useGlobalModalPortalStore();
  const { currentModalContent, hide, visible } = store;

  useMount(() => {
    const hideOnRouterChangeFn = () => {
      hide();
    };

    Router.events.on('routeChangeComplete', hideOnRouterChangeFn);
    Router.events.on('routeChangeError', hideOnRouterChangeFn);
  });

  if (!currentModalContent || !visible) {
    return null;
  }

  const { content } = currentModalContent;

  return <ModalPortalFullScreen>{content}</ModalPortalFullScreen>;
});

export const withGlobalModalPortal = (Component: any) => (props: any) => {
  const model = useCreateStore(GlobalModalPortalStore, {
    visible: false,
  });
  const Provider = useProvider(GlobalModalPortalStore);

  return (
    <Provider value={model}>
      <Component {...props} />
      <ModalComponent />
    </Provider>
  );
};

export interface GlobalModalPortalStoreInstance extends Instance<typeof GlobalModalPortalStore> {}
