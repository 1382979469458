import * as React from 'react';
import { off, on } from 'react-use/lib/misc/util';
import { useRafState } from 'react-use';

export interface State {
  x: number;
  y: number;
}

export const setScrollXOf = (element: HTMLElement | Window, xValue: number) => {
  if (element === window) {
    element.scroll(xValue, element.scrollY);
  } else {
    (element as HTMLElement).scrollLeft = xValue;
  }
};

export const setScrollYOf = (element: HTMLElement | Window, yValue: number) => {
  if (element === window) {
    element.scroll(element.scrollX, yValue);
  } else {
    (element as HTMLElement).scrollTop = yValue;
  }
};

export const getScrollXOf = (element: HTMLElement | Window) => {
  if (!element) {
    return 0;
  }

  if (element === window) {
    return element.scrollX;
  }
  return (element as HTMLElement).scrollLeft;
};

export const getScrollYOf = (element: HTMLElement | Window) => {
  if (!element) {
    return 0;
  }

  if (element === window) {
    return element.scrollY;
  }
  return (element as HTMLElement).scrollTop;
};

export const useScrollElement = (ref: React.RefObject<HTMLElement | Window>): State => {
  if (
    process.env.NODE_ENV === 'development' &&
    (typeof ref !== 'object' || typeof ref.current === 'undefined')
  ) {
    console.error('`useScroll` expects a single ref argument.');
  }

  const [state, setState] = useRafState<State>({
    x: 0,
    y: 0,
  });

  React.useEffect(() => {
    const scrollElm = ref.current;

    const handleScroll = () => {
      // We need to get latest element, as on-rendering, the ref may pointing to empty
      const currentElement = ref.current;
      if (currentElement) {
        setState((newState) => {
          // Check state for change, return same state if no change happened to prevent rerender
          // (see useState/setState documentation). useState/setState is used internally in useRafState/setState.
          return newState.x !== getScrollXOf(currentElement) ||
            newState.y !== getScrollYOf(currentElement)
            ? {
                x: getScrollXOf(currentElement),
                y: getScrollYOf(currentElement),
              }
            : newState;
        });
      }
    };

    handleScroll(); // update current position

    if (scrollElm) {
      on(scrollElm, 'scroll', handleScroll, {
        capture: false,
        passive: true,
      });
    }

    return () => {
      if (scrollElm) {
        off(scrollElm, 'scroll', handleScroll);
      }
    };
  }, [ref, setState]);

  return state;
};
