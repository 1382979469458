import * as React from 'react';
import styled from 'styled-components';

import { SpokeLoading } from './SpokeLoading';

const Main = styled.div`
  position: relative;
  height: 100%;
  .a-spin {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    place-items: center;
    z-index: 10001;
  }
  .spin-blur {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #fff;
    z-index: 10000;
  }
`;

export const SpokeSpinner = ({ children, spinning }) => {
  return (
    <Main className="spoke-spinner">
      {spinning && (
        <div className="spoke-spinner-overlay">
          <div className="a-spin">
            <SpokeLoading width={32} height={32} color="#D14242" />
          </div>
          <div className="spin-blur" />
        </div>
      )}
      {children}
    </Main>
  );
};
