import cl from 'classnames';
import { Instance, types as t } from 'mobx-state-tree';
import { useCreateStore, useProvider, useStore } from 'mobx-store-provider';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { Router } from 'next/router';
import * as React from 'react';
import withHydrationOnDemand from '@doltech/core/lib/hooks/withDolHydrationOnDemand';

import renderNode, { Div } from '../figma/renderNode';
import { observer } from 'mobx-react-lite';
import { useMount } from 'react-use';

const Modal = dynamic(() => import(/* webpackExports: "Modal" */ 'antd').then((mod) => mod.Modal), {
  loading: () => <div>Loading...</div>,
  ssr: false,
});

export interface ModalContent {
  content: JSX.Element | any;
  title?: any;
  closable?: boolean;
  maskClosable?: boolean;
  keyboard?: boolean;
  centered?: boolean;
  closeIcon?: any;
  width?: any;
  contentProps?: any;
  bodyStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  modalLess?: boolean;
  modalStyle?:
    | 'unstyled'
    | 'unstyled-full-height'
    | 'unstyled-full-height-not-center'
    | 'unstyled-full-screen'
    | 'unstyled-bottom-sheet';
  modalClassName?: string;
  isModalUnStyled?: boolean;
  modalRender?: (node: React.ReactNode) => React.ReactNode;
  onCancel?: (...args: any[]) => any;
  skipHide?: boolean;
}

export type GlobalModalContent = ModalContent;

export const GlobalModalStore = t
  .model('GlobalModalStore', {
    visible: t.optional(t.boolean, false),
  })
  .volatile(() => ({
    modals: {}, // hash table instead of array
    currentModalContent: null as GlobalModalContent,
    currentModalId: null,
  }))
  .actions((self) => ({
    registerModal(modalId: string, modalContent: GlobalModalContent) {
      self.modals[modalId] = modalContent;
      self.currentModalId = modalId;
    },
    showByModalId(modalId: string) {
      self.currentModalContent = self.modals[modalId];
      self.currentModalId = modalId;
      self.visible = true;
    },
    show(modalContent: GlobalModalContent) {
      self.visible = true;
      self.currentModalContent = modalContent;
      self.currentModalId = nanoid();
    },
    hide() {
      self.visible = false;
    },
    hideAndDestroy() {
      self.visible = false;
      self.currentModalContent = null;
    },
  }))
  .actions((self) => ({
    registerAndShowModal(modalId: string, modalContent: GlobalModalContent) {
      self.registerModal(modalId, modalContent);
      self.showByModalId(modalId);
    },
  }));

export const useGlobalModalStore = () => {
  return useStore(GlobalModalStore);
};

const ModalHydrateOnIdle = withHydrationOnDemand({
  on: ['idle'],
})(Modal);

const ModalComponent = observer(() => {
  const store = useGlobalModalStore();

  useMount(() => {
    const hideOnRouterChangeFn = () => {
      if (store?.currentModalContent?.skipHide) {
        return;
      }
      store.hide();
    };

    Router.events.on('routeChangeComplete', hideOnRouterChangeFn);
    Router.events.on('routeChangeError', hideOnRouterChangeFn);
  });

  if (!store.currentModalContent) {
    return null;
  }

  if (store.currentModalContent.modalLess) {
    return renderNode(
      Div,
      store.currentModalContent.content,
      store.currentModalContent.contentProps
    );
  }
  return (
    <ModalHydrateOnIdle
      className={cl(store.currentModalContent.modalStyle, store.currentModalContent.modalClassName)}
      visible={store.visible}
      title={store.currentModalContent.title}
      closable={store.currentModalContent.closable}
      closeIcon={store.currentModalContent.closeIcon}
      width={store.currentModalContent.width}
      style={store.currentModalContent.style}
      bodyStyle={store.currentModalContent.bodyStyle}
      footer={null}
      maskClosable={store.currentModalContent.maskClosable}
      centered={store.currentModalContent.centered}
      onCancel={store.currentModalContent.onCancel || store.hide}
      key={store.currentModalId}
      modalRender={store.currentModalContent.modalRender}
      keyboard={store.currentModalContent.keyboard}
    >
      {renderNode(Div, store.currentModalContent.content, store.currentModalContent.contentProps)}
    </ModalHydrateOnIdle>
  );
});

export const withGlobalModal = (Component: any) => (props: any) => {
  const model = useCreateStore(GlobalModalStore, {
    visible: false,
  });
  const Provider = useProvider(GlobalModalStore);

  return (
    <Provider value={model}>
      <Component {...props} />
      <ModalComponent />
    </Provider>
  );
};

export interface GlobalModalStoreInstance extends Instance<typeof GlobalModalStore> {}
