import jwtDecode from 'jwt-decode';
import { IAuthManager } from '../../../interface/IAuthManager.interface';

const offsetInSeconds = 60;

const isAccessTokenExpired = (token: string): boolean => {
  const decodedInfo = jwtDecode(token) as any;

  const now = Date.now().valueOf() / 1000 - offsetInSeconds;
  return typeof decodedInfo.exp !== 'undefined' && decodedInfo.exp < now;
};

export const withRefreshTokenOnExpired = (authManager: IAuthManager) => {
  const getAccessTokenFn = authManager.getAccessToken.bind(authManager);
  authManager.getAccessToken = async (forceRefresh?: boolean) => {
    if (forceRefresh) {
      return getAccessTokenFn(forceRefresh);
    }

    const token = await getAccessTokenFn(false);
    if (isAccessTokenExpired(token)) {
      return getAccessTokenFn(true);
    }
    return token;
  };

  return authManager;
};
