import { CurlHelper } from './CurlHelper';

export const curlFromAxiosRequest = (request) => {
  try {
    const curl = new CurlHelper(request);
    return curl.generateCommand();
    // eslint-disable-next-line no-empty
  } catch (ex) {}
  return '';
};
