import * as React from 'react';
import { isBrowser } from 'react-use/lib/misc/util';

type AppScrollElement = Window | HTMLElement | undefined;

interface AppScrollConfig {
  getAppScrollElement: () => AppScrollElement;
  getAppContainer: () => HTMLElement | undefined;
}

export let APP_SCROLL_ELEMENT = undefined; // TODO: to be used in normal functions &  store

const AppScrollContext = React.createContext<{
  appScrollRef: React.MutableRefObject<AppScrollElement>;
  appContainerRef: React.MutableRefObject<HTMLElement>;
}>(undefined);
export const withAppScroll =
  (config: AppScrollConfig) => (Component: React.ComponentType) => (props: any) => {
    const appScrollRef = React.useRef<AppScrollElement>(isBrowser ? config.getAppScrollElement() : null);
    const appContainerRef = React.useRef<HTMLElement>(isBrowser ? config.getAppContainer() : null);
    if (isBrowser) {
      APP_SCROLL_ELEMENT = config.getAppScrollElement();
    }

    React.useEffect(() => {
      appScrollRef.current = config.getAppScrollElement();
      appContainerRef.current = config.getAppContainer();
      APP_SCROLL_ELEMENT = config.getAppScrollElement();
    }, []);

    return (
      <AppScrollContext.Provider value={{ appScrollRef, appContainerRef }}>
        <Component {...props} />
      </AppScrollContext.Provider>
    );
  };

export const useAppScrollRef = () => React.useContext(AppScrollContext);
