import { IAuthManager } from '../../../interface/IAuthManager.interface';
import { onAuthStateChanged } from '@firebase/auth';

const isBrowser = () => typeof window !== 'undefined';

export const withAuthStateReady = (authManager: IAuthManager) => {
  let authStateReadyPromise = Promise.resolve(true);

  const getAccessTokenFn = authManager.getAccessToken.bind(authManager);
  authManager.getAccessToken = async (forceRefresh?: boolean) => {
    if (!forceRefresh) {
      return getAccessTokenFn(forceRefresh);
    }

    if (authManager.auth && isBrowser()) {
      authStateReadyPromise = new Promise<boolean>((resolve, reject) => {
        const obs = onAuthStateChanged(
          authManager.auth,
          (user) => {
            obs(); // unsubscribe
            resolve(true);
          },
          reject
        );
      });
    }

    // We should wait for auth init successfully before making any calls
    return authStateReadyPromise.then(() => {
      return getAccessTokenFn(forceRefresh);
    });
  };
  return authManager;
};
