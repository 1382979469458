import { environment } from '../environment/environment';

export const EXTERNAL_ACCESS = environment.REACT_AUTH_LOCAL_STORAGE_KEY;
export const FIRE_BASE_EXTERNAL_ACCESS = environment.REACT_AUTH_CURRENT_USER_ID_TOKEN;
export const AUTH0_LOGGED = 'auth0.loggedIn';
export const LOCAL_STORAGE_ANONYMOUS_KEY = 'user.anonymous.id';

export const getUserAnonymousId = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(LOCAL_STORAGE_ANONYMOUS_KEY);
  }
  return 'unknown';
};

export const DEFAULT_USER_INFO = {
  isAnonymous: false,
  metadata: {},
  providerData: [],
  createdAt: '',
  displayName: '...',
  email: '...',
  phoneNumber: '...',
  photoURL: 'https://dummyimage.com/100x100.png?text=anonymous',
  providerId: '...',
  uid: '...',
  userMetadata: {},
  appMetadata: {},
  roles: [],
};

export const LoginProvider = {
  GOOGLE: 'google.com',
  FACEBOOK: 'facebook.com'
} as const;
export type LoginProvider = typeof LoginProvider[keyof typeof LoginProvider];
