import { observer } from 'mobx-react-lite';
import { useCreateStore, useProvider, useStore } from 'mobx-store-provider';
import React from 'react';

import { clearRedirectLogin, isRedirectLogin } from './action/redirect';
import { getAuthManager, useAuthManager } from './authManager';
import { SpokeSpinner } from './SpokeSpinner';
import { flow, types as t } from 'mobx-state-tree';
import { DEFAULT_USER_INFO, LOCAL_STORAGE_ANONYMOUS_KEY } from './constants';
import { nanoid } from 'nanoid';
import * as Sentry from '@sentry/nextjs';

const defaultUserInfo = {
  id: '',
  isAnonymous: true,
  ...DEFAULT_USER_INFO,
};

export const UserAuthInfoStore = t
  .model('UserAuthInfoStore', {
    isLoggedIn: t.optional(t.boolean, false),
    isLoading: t.optional(t.boolean, false),
  })
  .volatile(() => ({
    userInfo: defaultUserInfo as any,
  }))
  .views((self) => ({
    isDefaultFakeUser() {
      return self.userInfo === defaultUserInfo;
    },
  }))
  .actions((self) => ({
    setLoading(newLoadingState: boolean) {
      self.isLoading = newLoadingState;
    },
    forceLogin() {
      self.isLoggedIn = true;
    },
    fetchUserInfo: flow(function* runFetchUserInfo() {
      let userLoggedIn = false;
      try {
        userLoggedIn = getAuthManager().isLoggedIn();
        self.isLoggedIn = userLoggedIn;
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
      const user = yield getAuthManager().getUserInfo();

      if (userLoggedIn && user) {
        Sentry.setUser({ username: user.uid, email: user.email });
        Sentry.setExtra('user_displayName', user.name);
        Sentry.setExtra('user_emailVerified', user.emailVerified);
        Sentry.setExtra('user_lastLoginAt', user.lastLoginAt);

        const userId = encodeURIComponent(user.uid);
        self.userInfo = {
          ...user,
          id: userId,
          isAnonymous: false,
        };
        return;
      }
      let anonymousUserId = localStorage.getItem(LOCAL_STORAGE_ANONYMOUS_KEY);
      if (!anonymousUserId) {
        anonymousUserId = nanoid();
        localStorage.setItem(LOCAL_STORAGE_ANONYMOUS_KEY, anonymousUserId);
      }
      self.userInfo = {
        id: anonymousUserId,
        isAnonymous: true,
        ...DEFAULT_USER_INFO,
      };
    }),
  }));

export const useGetAuthInfo = () => {
  const store = useStore(UserAuthInfoStore);
  const authManager = useAuthManager();
  return {
    isDefaultFakeUser: store?.isDefaultFakeUser,
    isLoggedIn: store?.isLoggedIn,
    userInfo: store?.userInfo,
    refetch: store?.fetchUserInfo,
    authManager,
    isLoading: store?.isLoading,
    forceLogin: store?.forceLogin,
  };
};

const SpokeLoadingGlobal = observer<any>(({ children }) => {
  const { isLoading } = useGetAuthInfo();
  return <SpokeSpinner spinning={isLoading}>{children}</SpokeSpinner>;
});

let isUpdatingToken = false;

export const withGlobalUserAuthInfo = (Component: any) => (props: any) => {
  const globalAuthCreateStore = useCreateStore(UserAuthInfoStore, {
    isLoading: false,
  });
  const authManager = useAuthManager();
  const GlobalAuthStoreProvider = useProvider(UserAuthInfoStore);
  authManager.updateAuthApp();

  const refreshTokenIfNeeded = React.useCallback(async () => {
    if (authManager.getExpiredTokenTimeStamps() - Date.now() < 20 * 60 * 1000) {
      await authManager.refreshToken();
      await globalAuthCreateStore.fetchUserInfo();
    }
  }, [authManager, globalAuthCreateStore]);

  React.useEffect(() => {
    isUpdatingToken = false;
    globalAuthCreateStore.fetchUserInfo();
  }, [globalAuthCreateStore]);

  React.useEffect(() => {
    refreshTokenIfNeeded();

    const refreshInterval = setInterval(() => {
      refreshTokenIfNeeded();
    }, 15 * 60 * 1000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [refreshTokenIfNeeded]);

  React.useEffect(() => {
    const isRedirect = isRedirectLogin();
    if (isRedirect) {
      globalAuthCreateStore.setLoading(true);
      authManager
        .handleRedirectResult()
        .then(async ({ result }) => {
          if (result?.user) {
            // After returning from the redirect when your app initializes you can obtain the result
            await (authManager as any).syncUserInfoToStorage(result?.user);
            if (result?.user?.accessToken) {
              globalAuthCreateStore.fetchUserInfo().finally(() => {
                globalAuthCreateStore.setLoading(false);
              });
            }
            clearRedirectLogin();
          }
          // Note: remove since Thanh,Luc confirm that we don't need to get user location
          // if (getCurrentAppName() === 'landing') {
          //   await apis.cloudFn.get(environment.REACT_TRACK_USER_LOCATION_HOST_NAME);
          // }
        })
        .catch(async (error) => {
          await (authManager as any).handleAuthError(error);
        })
        .finally(() => {
          globalAuthCreateStore.setLoading(false);
        });
    }

    const unsubscribe = authManager.onAuthStateChanged(async (user) => {
      if (user) {
        // user is signed in
        isUpdatingToken = true;
        if (!isUpdatingToken) {
          await globalAuthCreateStore.fetchUserInfo();
          isUpdatingToken = false;
        }
      } else {
        // authManager.logout();
      }
    });

    const unsubscribe2 = authManager.onIdTokenChanged(async (user) => {
      if (user && !isUpdatingToken) {
        isUpdatingToken = true;
        // user is signed in or token was refreshed
        await (authManager as any).updateIdToken();
        isUpdatingToken = false;
      }
    });

    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, [authManager, globalAuthCreateStore]);

  React.useEffect(() => {
    const doRefresh = () => {
      globalAuthCreateStore.fetchUserInfo();
    };
    window.addEventListener('refreshToken', doRefresh);
    return () => {
      window.removeEventListener('refreshToken', doRefresh);
    };
  }, [globalAuthCreateStore]);

  return (
    <GlobalAuthStoreProvider value={globalAuthCreateStore}>
      <SpokeLoadingGlobal>
        <Component {...props} />
      </SpokeLoadingGlobal>
    </GlobalAuthStoreProvider>
  );
};
