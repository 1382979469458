export const isBrowser = () => typeof window !== 'undefined';

export const CAN_USE_DOM: boolean =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined';

const documentMode = CAN_USE_DOM && 'documentMode' in document ? document.documentMode : null;

export const IS_APPLE: boolean = CAN_USE_DOM && /Mac|iPod|iPhone|iPad/.test(navigator.platform);

export const IS_FIREFOX: boolean =
  CAN_USE_DOM && /^(?!.*Seamonkey)(?=.*Firefox).*/i.test(navigator.userAgent);

export const CAN_USE_BEFORE_INPUT: boolean =
  CAN_USE_DOM && 'InputEvent' in window && !documentMode
    ? 'getTargetRanges' in new window.InputEvent('input')
    : false;

export const IS_SAFARI: boolean =
  CAN_USE_DOM && /Version\/[\d.]+.*Safari/.test(navigator.userAgent);

export const IS_IOS: boolean =
  CAN_USE_DOM && /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

export const IS_ANDROID: boolean = CAN_USE_DOM && /Android/.test(navigator.userAgent);

export const IS_CHROME: boolean = CAN_USE_DOM && /^(?=.*Chrome).*/i.test(navigator.userAgent);
export const IS_APPLE_WEBKIT =
  CAN_USE_DOM && /AppleWebKit\/[\d.]+/.test(navigator.userAgent) && !IS_CHROME;

export const IS_ANDROID_CHROME: boolean = CAN_USE_DOM && IS_ANDROID && IS_CHROME;

export const isSafari = () =>
  isBrowser() &&
  navigator.userAgent.indexOf('Safari') > -1 &&
  navigator.userAgent.indexOf('Chrome') === -1;

export const isChrome = () =>
  isBrowser() && /Chrome/i.test(navigator.userAgent) && /Google Inc/i.test(navigator.vendor);

export const isMobile =
  isBrowser() &&
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const isTouchDevice = () =>
  'ontouchstart' in window || navigator?.maxTouchPoints > 0 || navigator?.maxTouchPoints > 0;

export const isFirefox = () =>
  isBrowser() && /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);

export const isCocCoc = () => isBrowser() && navigator.userAgent.includes('coc_coc_browser');

const getDeviceVersionNumbers = () => {
  const match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return [parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3] || '0', 10)];
};

const getVersionNumbersFromVersion = (version) => {
  return version.split('.').map((verString) => parseInt(verString || '0', 10));
};

export const isIos = () => /iP(hone|od|ad)/i.test(navigator.userAgent);

export const isIosEq = (version) => {
  if (isIos()) {
    const versionNumbers = getDeviceVersionNumbers();
    const comparableVersionNumbers = getVersionNumbersFromVersion(version);

    return (
      versionNumbers[0] === comparableVersionNumbers[0] &&
      versionNumbers[1] === comparableVersionNumbers[1] &&
      versionNumbers[2] === comparableVersionNumbers[2]
    );
  }
  return false;
};

export const isIosAbove = (version) => {
  if (isIos()) {
    const versionNumbers = getDeviceVersionNumbers();
    const minVersionNumbers = getVersionNumbersFromVersion(version);

    if (versionNumbers[0] > minVersionNumbers[0]) {
      return true;
    }

    if (versionNumbers[0] === minVersionNumbers[0] && versionNumbers[1] > minVersionNumbers[1]) {
      return true;
    }
    return (
      versionNumbers[0] === minVersionNumbers[0] &&
      versionNumbers[1] === minVersionNumbers[1] &&
      versionNumbers[2] > minVersionNumbers[2]
    );
  }

  return false;
};

export const isIosBelow = (version) => {
  if (isIos()) {
    const versionNumbers = getDeviceVersionNumbers();
    const maxVersionNumbers = getVersionNumbersFromVersion(version);

    if (versionNumbers[0] < maxVersionNumbers[0]) {
      return true;
    }

    if (versionNumbers[0] === maxVersionNumbers[0] && versionNumbers[1] < maxVersionNumbers[1]) {
      return true;
    }
    return (
      versionNumbers[0] === maxVersionNumbers[0] &&
      versionNumbers[1] === maxVersionNumbers[1] &&
      versionNumbers[2] < maxVersionNumbers[2]
    );
  }

  return false;
};

export const requestIdleCallbackForSafari = (fn) => {
  // eslint-disable-next-line @typescript-eslint/no-implied-eval
  setTimeout(fn, 100);
};
