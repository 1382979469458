/* eslint-disable react/destructuring-assignment */
import { apis } from '@doltech/core/lib/api/api.config';
import { requestIdleCallbackForSafari } from '@doltech/utils/lib/platform';
import * as React from 'react';
import { apiPublicUrls } from '@doltech/core/lib/api/api.public-urls';

export const updateUserView = async (pageId) => {
  const { data } = await apis.publicPageManagement.put<any>(
    apiPublicUrls.pageManagement.UPDATE_USER_INTERACTION,
    {
      type: 'VIEW',
      id: pageId,
    }
  );
  return data;
};

// export const useLoadThanhSeoScript = () => {
//   React.useEffect(() => {
//     function loadScriptIfNeeded() {
//       const queryParams = new URLSearchParams(window.location.search);
//       if (queryParams.toString() === '') {
//         const script = document.createElement('script');
//         script.id = 'cssminifier';
//         script.type = 'text/javascript';
//         script.src =
//           'https://security.datacenters.vn/public/js/customer.js?token=$2a$08$bk5h209yBc/zrhtHJMhI..V/g.CL2Rj1ADRSDOI5CXxE7rKdI8phi';
//         script.async = true;
//         document.head.appendChild(script);
//       }
//     }
//     setTimeout(() => {
//       if (environment.production) {
//         loadScriptIfNeeded();
//       }
//     }, 1000);
//   }, []);
// };

export const withUpdateViewCount = (Component: any) => (props: any) => {
  React.useEffect(() => {
    window.addEventListener('load', () => {
      requestIdleCallbackForSafari(() => {
        if (typeof window === 'undefined') return;
        if (props?.pageProps?.urlInfo?.id) {
          updateUserView(props?.pageProps?.urlInfo?.id);
        }
      });
    });
  }, [props?.pageProps?.urlInfo?.id]);
  return <Component {...props} />;
};
