import { Auth } from '@firebase/auth';
import jwtDecode from 'jwt-decode';

import { environment } from '../../environment/environment';
import { EXTERNAL_ACCESS } from '../constants';
import { isBrowser } from '../utils';
import { ExternalAccess } from '../interface/auth.interface';
import { ITokenAuthManager } from '../interface/ITokenAuthManager.interface';

export class TokenAuthManager implements ITokenAuthManager {
  readonly auth: Auth;

  get accessToken() {
    if (isBrowser && localStorage.getItem(EXTERNAL_ACCESS)) {
      const externalAccess: ExternalAccess = JSON.parse(localStorage.getItem(EXTERNAL_ACCESS));
      return externalAccess.accessToken;
    }
    return '';
  }

  get isTokenExpired() {
    return this.checkIfTokenExpired(this.accessToken);
  }

  get isTokenValid(): boolean {
    return this.accessToken && !this.isTokenExpired;
  }

  async getAccessToken(): Promise<string> {
    return this.accessToken;
  }

  onAuthStateChanged() {
    return () => {};
  }

  setAccessToken(accessToken = ''): void {
    if (isBrowser) {
      localStorage.setItem(
        EXTERNAL_ACCESS,
        JSON.stringify({
          accessToken,
        })
      );
    }
  }

  async getUserInfo() {
    return {
      isAnonymous: false,
      metadata: {},
      providerData: [],
      createdAt: '',
      displayName: '',
      email: '',
      phoneNumber: '',
      photoURL: '',
      providerId: '',
      uid: '',
      userMetadata: '',
      appMetadata: '',
      roles: [],
    };
  }

  getCurrentUser() {
    if (isBrowser()) {
      try {
        return JSON.parse(window.localStorage.getItem(environment.REACT_AUTH_CURRENT_USER_INFO));
        // eslint-disable-next-line no-empty
      } catch (ex) {}
    }
    return null;
  }

  async isCompleteSignUp() {
    return this.isLoggedIn();
  }

  decodeToken(token): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      throw new Error(`Failed to parse token with error: ${error.message}`);
    }
  }

  checkIfTokenExpired(token) {
    const now = Date.now().valueOf() / 1000;
    const decoded = this.decodeToken(token);
    return Boolean(decoded.exp) && decoded.exp < now;
  }

  isLoggedIn(): boolean {
    return Boolean(this.accessToken);
  }
}
