import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import { colorsV2 } from '../colors-v2';

const fontWeights = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

type Breakpoints = {
  fromTablet: string;
  fromDesktop: string;
  fromLgDesktop: string;
} | string[] | any;

const breakpoints: Breakpoints = ['768px', '1366px', '1920px'];

breakpoints.fromTablet = breakpoints[0];
breakpoints.fromDesktop = breakpoints[1];
breakpoints.fromLgDesktop = breakpoints[2];

export const dolTheme = {
  breakpoints,
  colors: colorsV2,
  fontWeights,
};

export type DOLThemeType = typeof dolTheme;

export const withDolSystemDesignThemeProvider = (Component: any) => (props: any) =>
  (
    <ThemeProvider theme={dolTheme}>
      <Component {...props} />
    </ThemeProvider>
  );
