import { apis } from '@doltech/core/lib/api/api.config';
import { apiUrls } from '@doltech/core/lib/api/api.urls';
import { useUserTracker } from '@doltech/core/lib/hooks/withCustomerIO';
import { definitions } from '@doltech/membership/dto';
import { DynamicPageProps } from '@doltech/routeDefinition';
import { PageSharingActionEnum } from '@doltech/utils/lib/constants';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { noop } from 'lodash';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useMutation } from 'react-query';

import { useGlobalModalStore } from '../../../hocs/withGlobalModal';
import { recordPageSharing } from '../api/membership';
import { freeUserAuthenticationModalContent } from '../FreeUserAuthenticationModalContent';
import { verifyPhoneModalContent } from '../VerifyPhoneModalContent';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';

interface ConfigModel {
  title?: string | React.ReactNode;
  defaultBack?: string;
  onSuccess?: () => void;
  noRedirect?: boolean;
}

interface UserAuthenticationModel {
  openLoginModal?: (extraConfig: any) => void;
  forceLoginToProceedAction?: (callBack, extraConfig?: any) => void;
}

const UserAuthenticationContext = React.createContext<UserAuthenticationModel>({});

export const useUserAuthentication = (): UserAuthenticationModel =>
  React.useContext(UserAuthenticationContext);

export const withUserAuthentication =
  <P extends DynamicPageProps, D = any>(
    defaultConfig: ConfigModel = {
      defaultBack: '/',
      noRedirect: true,
    },
    showVerifyPhoneOnLoad = false
  ) =>
  (Component: React.ComponentType<P> & NextPage<P>): React.FC<P> & NextPage<P> & D => {
    const ResponseComponent = observer((props: P) => {
      const { seoData } = props;

      const router = useRouter();
      const { updateUserAttribute } = useUserTracker();
      const { isLoggedIn, refetch, authManager } = useGetAuthInfo();
      const { show, hideAndDestroy, visible } = useGlobalModalStore();
      const { referralCode } = router.query;

      const hasReferral = React.useMemo(
        () => Boolean(router.isReady && referralCode),
        [referralCode, router.isReady]
      );
      const recordPageSharingMutation = useMutation(recordPageSharing);

      const onRecordReferral = async (isSignUp = false) => {
        if (hasReferral) {
          const type = isSignUp
            ? PageSharingActionEnum.REGISTER_ACCOUNT
            : PageSharingActionEnum.ACCESS_PAGE;
          await recordPageSharingMutation.mutateAsync({
            type,
            pageId: seoData?.id,
            referralCode: referralCode as string,
          });

          const { data: premiumInfo } = await apis.membership.get<definitions['MyPremiumInfoDTO']>(
            apiUrls.membership.GET_MY_PREMIUM_USER_INFO
          );
          if (isSignUp && premiumInfo) {
            updateUserAttribute({
              is_referred: true,
              premium_feature_end_date: Math.floor(
                new Date(premiumInfo.dpExpireAt).getTime() / 1000
              ),
            });
          }
        }
      };

      const openLoginModal = (extraConfig: any) =>
        show({
          ...freeUserAuthenticationModalContent,
          contentProps: {
            ...extraConfig,
            key: nanoid(),
            onSuccess: (isSignUp) => {
              refetch();
              extraConfig.onSuccess?.();
              onRecordReferral(isSignUp);
            },
            onCancelLogin: () => {
              hideAndDestroy();
              if (!defaultConfig.noRedirect && defaultConfig?.defaultBack) {
                router.replace(extraConfig?.defaultBack || defaultConfig?.defaultBack || '/');
              }
            },
            title: extraConfig?.title || 'Đăng ký',
            onFailed: noop,
            isGoBack: false,
          },
        });

      const openVerifyPhoneModal = (extraConfig?: any) =>
        show({
          ...verifyPhoneModalContent,
          contentProps: {
            ...extraConfig,
            key: nanoid(),
            visible: true,
            onVerifySuccess: async (isSignUp) => {
              await refetch();
              extraConfig?.onSuccess?.();
              hideAndDestroy();
              authManager.saveLocalPhoneVerified(true);
              onRecordReferral(isSignUp);
            },
            onBack: async () => {
              await authManager.logout();
              await refetch();
              openLoginModal({});
            },
            onSignAction: async () => {
              await authManager.logout();
              await refetch();
              openLoginModal(extraConfig);
            },
            title: 'Xác nhận tài khoản',
            showBack: true,
          },
        });

      const forceLoginToProceedAction = (callBack, extraConfig = {} as any) => {
        if (visible) return;
        if (!isLoggedIn) {
          openLoginModal(extraConfig);
          return;
        }
        if (
          authManager.needVerifyPhone().then(() => {
            openVerifyPhoneModal(extraConfig);
          })
        ) {
          return;
        }
        callBack();
      };

      React.useEffect(() => {
        authManager.needVerifyPhone().then((isNeedVerifyPhone) => {
          if (isNeedVerifyPhone && !visible && isLoggedIn && showVerifyPhoneOnLoad) {
            openVerifyPhoneModal();
          }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isLoggedIn]);

      return (
        <UserAuthenticationContext.Provider
          value={{
            openLoginModal,
            forceLoginToProceedAction,
          }}
        >
          <Component {...props} />
        </UserAuthenticationContext.Provider>
      );
    });

    hoistNonReactStatic(ResponseComponent, Component);

    return ResponseComponent as React.FC<P> & NextPage<P> & D;
  };
