import { apis } from '@doltech/core/lib/api/api.config';
import { apiUrls } from '@doltech/core/lib/api/api.urls';
import { components } from '@doltech/dto';

interface UseSignInTrackingDTO {
  userId: string;
  signInAt: string;
}

export const postUserActivity = async (userId) => {
  const response = await apis.goUserService.post<components['schemas']['ResponseDTO']>(
    apiUrls.activityService.POST_TRACKING,
    {
      records: [
        {
          value: {
            action: 'user-active:update-status',
            byUserId: userId,
          },
        },
      ],
    }
  );
  return response.data;
};

export const postUserSignInTracking = async (requestBody: UseSignInTrackingDTO, userId) => {
  const response = await apis.kafkaRest.post<components['schemas']['ResponseDTO']>(
    apiUrls.kafkaRest.USER_ACTIVITY_TRACKING,
    {
      records: [
        {
          value: {
            action: 'user-activity:tracking-user-sign-in',
            byUserId: userId,
            updatedData: requestBody,
          },
        },
      ],
    }
  );
  return response.data;
};

/**
 * Note: at the time I write this definition, it's only support 1 enum NEWS and VIEW
 * This kafka service does not have swagger on gateway, so we'll have to manually definition type for it
 */
export const ResourceTypeEnum = {
  NEWS: 'NEWS',
} as const;

export type ResourceTypeEnum = (typeof ResourceTypeEnum)[keyof typeof ResourceTypeEnum];

export const InteractionTypeEnum = {
  VIEW_TEST: 'VIEW',
} as const;
export type InteractionTypeEnum = (typeof InteractionTypeEnum)[keyof typeof InteractionTypeEnum];
interface RecordInteractionParams {
  resourceId: string;
  resourceType: ResourceTypeEnum;
  interactionType: InteractionTypeEnum;
}

export const recordResourceInteraction = async (requestBody: RecordInteractionParams) => {
  const response = await apis.kafkaRest.post<components['schemas']['ResponseDTO']>(
    apiUrls.kafkaRest.RECORD_RESOURCE_INTERACTION,
    {
      records: [
        {
          value: {
            action: 'gw:record-resource-interaction',
            updatedData: requestBody,
          },
        },
      ],
    }
  );
  return response.data;
};
