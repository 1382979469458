/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { environment } from '../environment/environment';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useAuthManager } from '../auth/authManager';
import { getSignedUpDate } from '../auth/utils';

export interface WithCustomerIOProps {
  siteId: string;
  env: string[];
}

interface ReferralAttribute {
  is_referred: boolean; // TRUE: this user is referred by another user
  referral_invite_url: string; // user's referral url
}

interface PremiumFeatureAttribute {
  is_used_premium_feature: boolean; // TRUE: user used some premium features
  last_used_premium_feature_date: number; // in seconds
  premium_feature_end_date: number; // in seconds
}

interface UserAttributes extends Partial<PremiumFeatureAttribute>, Partial<ReferralAttribute> {
  id: string;
  email?: string;
  name?: string;
  created_at?: number; // in seconds
  last_login_date?: number; // in seconds
}

interface CustomerIOTracking {
  identify: (user: UserAttributes) => void;
  track: (eventId: string, data?: any) => void;
  page: (url: string, data?: any) => void;
}

export const useCustomerIO = () => {
  const getTracker = React.useCallback(() => {
    const wi = window as any;
    if (wi && wi._cio) {
      return wi._cio as CustomerIOTracking;
    }

    return {
      identify: (user) => {},
      track: (eventId) => {},
      page: (url) => {},
    } as CustomerIOTracking;
  }, []);

  return { getTracker };
};

const usePageTracking = () => {
  const router = useRouter();
  const history = React.useMemo(() => [], []);
  const { getTracker } = useCustomerIO();

  React.useEffect(() => {
    const routerChangeCompleteHandler = () => {
      const curPath = document?.location?.href;
      getTracker().page(curPath, {
        referer: history.pop() || '',
      });
      history.push(curPath);
    };

    router.events.on('routeChangeComplete', routerChangeCompleteHandler);
    return () => {
      router.events.off('routeChangeComplete', routerChangeCompleteHandler);
    };
  }, [getTracker, history, router.events]);
};

export const useUserTracker = () => {
  const authManager = useAuthManager();
  const { getTracker } = useCustomerIO();

  const updateUserAttribute = React.useCallback(
    async (data: Partial<UserAttributes>) => {
      const isLoggedIn = await authManager.isLoggedIn();
      if (isLoggedIn) {
        const userInfo = await authManager.getUserInfo();
        getTracker().identify({
          id: userInfo.uid,
          email: userInfo.email,
          name: userInfo.displayName,
          created_at: Math.floor(Number(getSignedUpDate(userInfo)) / 1000),
          ...data,
        });
      }
    },
    [authManager, getTracker]
  );

  const userLoggedIn = React.useCallback(
    () =>
      updateUserAttribute({
        last_login_date: Math.floor(Date.now() / 1000),
      }),
    [updateUserAttribute]
  );

  return { updateUserAttribute, userLoggedIn };
};

export const withCustomerIO =
  ({ env, siteId }: WithCustomerIOProps) =>
  (Component: any) =>
  (props: any) => {
    usePageTracking();

    return (
      <>
        {env.includes(environment.REACT_APP_ENV) && (
          <Script id="show-banner" strategy="lazyOnload">
            {` var _cio = _cio || [];
            (function() {
              var a,b,c;a=function(f){return function(){_cio.push([f].
              concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
              "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
              var t = document.createElement('script'),
                  s = document.getElementsByTagName('script')[0];
              t.async = true;
              t.id    = 'cio-tracker';
              t.setAttribute('data-site-id', '${siteId}');
              t.src = 'https://assets.customer.io/assets/track.js';
              s.parentNode.insertBefore(t, s);
            })();`}
          </Script>
        )}

        <Component {...props} />
      </>
    );
  };
