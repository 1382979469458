export const dolSentrySharedConfig = {
  tracesSampleRate: 0,
  ignoreErrors: [
    'Request failed with status code 401',
    'ResizeObserver loop limit exceeded',
    'Non-Error promise rejection captured',
    'zaloJSV2',
    'Hydration failed because the initial UI does not match what was rendered on the server.',
    'This Suspense',
    'Suspense boundary',
    'Firebase: Error',
    'Text content does not match server-rendered HTML.',
    'Cannot resolve a DOM node from Slate node: {"text":""}',
    'resolve a Slate',
    'AbortError: The operation was aborted.',
    'AbortError: The play() request was interrupted by a new load request.',
    'Request aborted',
    'ResizeObserver loop',
    'Pending promise was never set',
    'Loading CSS chunk',
    'Loading chunk',
    "Failed to execute 'setStart' on 'Range'",
    'Cancel rendering route',
    "Failed to execute 'setEnd' on 'Range'",
    'Error: UnknownError: Connection to Indexed Database server lost. Refresh the page to try again',
    '[mobx-state-tree] No matching type for union',
    "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
    'Error: RangeError: Out of memory',
    'Error: timeout of 30000ms exceeded',
    'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
    'AbortError: The play() request was interrupted by a call to pause()',
    'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
    'Request failed with status code 404',
    'Cannot get the leaf node at path',
    'Failed to fetch',
    'Rendered more hooks than during the previous render.',
    'Error: QuotaExceededError: Encountered full disk while opening backing store for indexedDB.open.',
    'Network Error',
  ],
};
