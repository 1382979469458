import Script from 'next/script';
import * as React from 'react';

import { environment } from '../../environment/environment';

export interface WithGTMProps {
  gtmId: string;
  env: string[];
}

const gtmStyles: any = { display: 'none', visibility: 'hidden' };

export const withGTM =
  ({ env, gtmId }: WithGTMProps) =>
  (Component: any) =>
  (props: any) => {
    return (
      <>
        <Component {...props} />

        {env.includes(environment.REACT_APP_ENV) && (
          <>
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `
                (function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', '${gtmId}');
            `,
              }}
            />
            <noscript>
              <iframe
                title="gtm"
                src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
                height="0"
                width="0"
                style={gtmStyles}
              />
            </noscript>
          </>
        )}
      </>
    );
  };
