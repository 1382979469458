export const truncateMiddle = (value: string, width: number) => {
  const maxTextLength = (width - 10) / 9.5;
  const halfText = maxTextLength / 2;
  if (value && value.length > maxTextLength) {
    return `${value.slice(0, halfText)} ... ${value.slice(-halfText)}`;
  }
  return value;
};

export const numberToABC = (num: number): string => {
  let s = '';
  let t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = (num - t) / 26;
    num = num < 1 ? 0 : num;
  }
  return s.trim() || undefined;
};

export const numberToRoman = (num: number): string => {
  if (isNaN(num)) return 'NaN';
  const digits = String(+num).split('');
  const key = [
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ];
  let roman = '';
  let i = 2;
  while (i !== 0) {
    roman = (key[+digits.pop() + i * 10] || '') + roman;
    i -= 1;
  }
  return Array(+digits.join('') + 1).join('M') + roman;
};

export const isNotEmpty = (str: string) => typeof str === 'string' && str.trim().length > 0;
export const isEmpty = (str: string) => typeof str === 'string' && str.trim().length === 0;

export const aBCToNumber = (str: string) => {
  return str.charCodeAt(0) - 65;
};

export const getValueAtIndex = (array: any, index) => {
  if (array) {
    // why not use array[index] -= 1 to make it work with mobx observable
    return array.find((_item, itemIndex) => itemIndex === index);
  }
  return [];
};

export const convertCurlyQuotationMark = (textContainQuotationMarks: string) =>
  textContainQuotationMarks.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');

interface SlugifyParams {
  text: string;
  separator?: string;
  trimMinusEndEnabled?: boolean;
  trimMinusStartEnabled?: boolean;
}

export const slugify = ({
  text,
  separator,
  trimMinusEndEnabled = false,
  trimMinusStartEnabled = true,
}: SlugifyParams) => {
  const sets = [
    { to: 'a', from: '[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'ae', from: '[Ä]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[ŒÖ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ŚŜŞŠ]' },
    { to: 'ss', from: '[ß]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'ue', from: '[Ü]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: "[·_,:;']" },
  ];

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to);
  });

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-/]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/\/\/+/g, '/'); // Replace multiple / with single /
  if (trimMinusStartEnabled) {
    text = text.replace(/^-+/, ''); // Trim - from start of text
  }
  if (trimMinusEndEnabled) {
    text = text.replace(/-+$/, ''); // Trim - from end of text
  }

  text = text.toString().toLowerCase().trim();

  if (typeof separator !== 'undefined' && separator !== '-') {
    text = text.replace(/-/g, separator);
  }

  return text;
};

export const getPrefixText = (prefix) => {
  if (prefix) {
    return `${prefix} `;
  }
  return '';
};

export const getTocTitleById = (id, tocItems) => {
  const foundTocItem = tocItems.find((item) => item.id === id);

  return `${getPrefixText(foundTocItem.prefix)}${foundTocItem.text}`;
};

export const isEqualIgnoreCaseTrim = (text1: string, text2: string) => {
  if (text1 && text2) {
    return text1.toLowerCase().trim() === text2.toLowerCase().trim();
  }
  return false;
};

export const nonAccentVietnamese = (str) => {
  if (!str) {
    return '';
  }
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const isEmailValid = (email) => {
  const rfc2822EmailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
  return rfc2822EmailRegex.test(email);
};

export const calculateTextLength = (
  text: string,
  font: {
    weight?: string;
    size?: string;
    family?: string;
  } = {
    weight: '600',
    size: '16px',
    family: 'Inter',
  }
) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `${font.weight} ${font.size} ${font.family}`;
  const metrics = context.measureText(text);
  return Math.ceil(metrics.width);
};

export const inputNumberOnlyCheck = (e) => {
  const { value } = e.target;
  if (value.startsWith('.')) {
    return undefined;
  }
  const re = /^[0-9]*[.]{0,1}[5,0]*$/;
  if (re.test(value) && value <= 10) {
    const [, after] = value.split('.');
    if (after && after.length > 1) {
      return undefined;
    }
    return value;
  }
  return undefined;
};

export const calculateFitTextFontSize = ({
  text,
  font,
  containerWidth = 648,
  containerHeight = 250,
}: {
  text?: string;
  font?: {
    weight?: string;
    minSize?: number;
    maxSize?: number;
    family?: string;
    lineHeightRatio?: number;
  };
  containerWidth?: number;
  containerHeight?: number;
}) => {
  const {
    weight = '400',
    minSize = 14,
    maxSize = 32,
    family = 'Inter',
    lineHeightRatio = 1.25,
  } = font;

  const maxLineHeight = maxSize * lineHeightRatio;
  const maxRow = Math.floor(containerHeight / maxLineHeight - 1);

  // measure text
  const textMeasureWidth = calculateTextLength(text, {
    family,
    weight,
    size: `${maxSize}px`,
  });

  // measure container
  const maxMeasureWidth = containerWidth * maxRow;

  if (textMeasureWidth < maxMeasureWidth) {
    return maxSize;
  }

  const fitSize = maxSize * (maxMeasureWidth / textMeasureWidth);

  if (fitSize < minSize) {
    return minSize;
  }

  return Math.floor(fitSize);
};

export const getBaseEmail = (email: string) => {
  if (email?.length > 0) {
    if (email.includes('@gmail.com')) {
      const splits = email.split('@gmail.com');
      return `${splits[0].replace(/\./g, '')}@gmail.com`;
    }
  }
  return email;
};

export const getTextOnly = (text) => {
  return text.replace(/[^a-zA-Z- ]/g, '');
};
