/**
 * We want to synchronize token refresher process ( not to keep requesting new token if already requested on )
 */
import { IAuthManager } from '../../../interface/IAuthManager.interface';

export const withRefreshTokenSynchronized = (authManager: IAuthManager) => {
  let refreshTokenPromise: Promise<string>;
  const getAccessTokenFn = authManager.getAccessToken.bind(authManager);

  authManager.getAccessToken = (forceRefresh?: boolean) => {
    if (forceRefresh) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = getAccessTokenFn(forceRefresh).then((data) => {
          refreshTokenPromise = null;
          return data;
        });
      }
      return refreshTokenPromise;
    }
    return getAccessTokenFn(false);
  };

  return authManager;
};
