import { IAuthManager } from './interface/IAuthManager.interface';

let authManager: IAuthManager;

let currentAppName = '';

export const getCurrentAppName = () => currentAppName;
export const setCurrentAppName = (appName: string) => (currentAppName = appName);

export const useAuthManager = <T extends IAuthManager>(): T => {
  return authManager as T;
};

export const getAuthManager = <T extends IAuthManager>(): T => {
  return authManager as T;
};

export const setAuthManager = (auth: IAuthManager) => {
  console.info('Init Auth Manager');
  authManager = auth;
};
