const IS_REDIRECT_KEY = 'isRedirect';

export const markRedirectLogin = () => {
  sessionStorage.setItem(IS_REDIRECT_KEY, 'true');
};

export const clearRedirectLogin = () => {
  sessionStorage.removeItem(IS_REDIRECT_KEY);
};

export const isRedirectLogin = () => {
  return sessionStorage.getItem(IS_REDIRECT_KEY);
};
