/* eslint-disable react/destructuring-assignment */
import { InternalPageLink } from '@doltech/routeDefinition';
import { PageTypeEnum } from '@doltech/utils/lib/constants';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { useCreateStore, useProvider } from 'mobx-store-provider';
import { GetStaticPropsContext, NextPage } from 'next';
import * as React from 'react';

import { retainOnlyUrlOfStaticPageLink } from '../../../../hocs/retainOnlyUrlOfStaticPageLink';
import { getLandingSidebarInitialState } from '../model/landing-shared-data.hook';
import { LandingSharedDataStore } from '../model/landing-shared-data.store';

export interface LandingSharedLayoutProps {
  internalPageLink: InternalPageLink;
  pageTypeGroup: PageTypeEnum;
}

export const withLandingSharedLayout = <P extends LandingSharedLayoutProps>(
  Component: React.ComponentType<P> & NextPage<P>
): React.FC<P> & NextPage<P> => {
  const ResponseComponent = (props: P) => {
    const { pageTypeGroup } = props;
    let internalPageLink = props?.internalPageLink;
    if (!internalPageLink) {
      internalPageLink = (props as any)?.pageProps?.data?.internalPageLink;
    }
    const sidebarStore = useCreateStore(
      LandingSharedDataStore,
      getLandingSidebarInitialState({ internalPageLink, pageTypeGroup })
    );

    const SideBarProvider = useProvider(LandingSharedDataStore);

    return (
      <SideBarProvider value={sidebarStore}>
        <Component {...props} />
      </SideBarProvider>
    );
  };

  hoistNonReactStatic(ResponseComponent, Component);

  return ResponseComponent;
};

export function withLandingSharedLayoutStaticProps(getStaticPropsFunc?) {
  return async (context: GetStaticPropsContext) => {
    let commonData = {};
    const { getCommonDataPreRendering } = await import('../api/pages-landing.m2m');

    try {
      commonData = await retainOnlyUrlOfStaticPageLink(await getCommonDataPreRendering());
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('getStaticProps error:', ex);
      throw ex;
    }

    if (getStaticPropsFunc) {
      const { props, ...restProps } = await getStaticPropsFunc(context, commonData);
      return {
        props: {
          ...commonData,
          ...props,
        },
        ...restProps,
      };
    }

    return {
      props: {
        ...commonData,
      },
      revalidate: 10, // 10 seconds of throttle stale while revalidate
    };
  };
}

export function withLandingSharedLayoutSeverProps() {
  return async (context: GetStaticPropsContext) => {
    let commonData = {};
    const { getCommonDataPreRendering } = await import('../api/pages-landing.m2m');

    try {
      commonData = await retainOnlyUrlOfStaticPageLink(await getCommonDataPreRendering());
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('getStaticProps error:', ex);
      throw ex;
    }

    return {
      props: {
        ...commonData,
      },
    };
  };
}
