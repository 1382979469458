import dynamic from 'next/dynamic';
import * as React from 'react';

import {GlobalModalContent} from '../../hocs/withGlobalModal';

const FreeUserAuthentication = dynamic(() => import('./FreeUserAuthentication'), { ssr: false });

export const freeUserAuthenticationModalContent: GlobalModalContent = {
  content: (props) => <FreeUserAuthentication {...props} />,
  title: null,
  closable: false,
  width: 410,
  modalLess: true,
};


