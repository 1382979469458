import { apis } from '@doltech/core/lib/api/api.config';
import { apiUrls } from '@doltech/core/lib/api/api.urls';
import { definitions } from '@doltech/membership/dto';
import { apiPublicUrls } from '@doltech/core/lib/api/api.public-urls';

interface EmailConfirmMutation {
  verificationCode: string;
}

export const recordPageSharing = (data: definitions['PageSharingRecordDTO']) =>
  apis.membership.post(apiUrls.membership.POST_RECORD_PAGE_SHARING, data);

export const emailConfirmPublic = (data: EmailConfirmMutation) =>
  apis.publicUser.post(apiPublicUrls.user.POST_EMAIL_CONFIRM, data);
