import { useAuthManager } from '@doltech/core/lib/auth/authManager';
import * as React from 'react';
import { postUserActivity, postUserSignInTracking } from './api/activity';

const ACTIVE_TIME_OUT_IN_MINUTES = 10;

const ACTUAL_TIME_OUT = ACTIVE_TIME_OUT_IN_MINUTES * 60000;
let lastSuccess = 0;

export const withUserActivity = (WrappedComponent: any) => (props: any) => {
  const authManager = useAuthManager();
  const isLoggedIn = authManager.isLoggedIn();

  const handlePostUserSignInTracking = React.useCallback(async () => {
    const userId = (await authManager.getUserInfo()).uid;
    await postUserSignInTracking(
      {
        userId,
        signInAt: new Date().toISOString(),
      },
      userId
    );
  }, [authManager]);

  React.useEffect(() => {
    const handleActionThrottle = async () => {
      const completedSignUp = await authManager.isCompleteSignUp();
      if (lastSuccess > new Date().getTime() - ACTUAL_TIME_OUT) {
        return;
      }
      if (completedSignUp) {
        await postUserActivity(
          (
            await authManager.getUserInfo()
          ).uid
        );
        lastSuccess = new Date().getTime();
      }
    };
    document.addEventListener('click', handleActionThrottle, true);
    return () => {
      document.removeEventListener('click', handleActionThrottle, true);
    };
  }, [authManager]);

  React.useEffect(() => {
    if (isLoggedIn) {
      handlePostUserSignInTracking();
    }
  }, [isLoggedIn, handlePostUserSignInTracking]);

  return <WrappedComponent {...props} />;
};
