import dynamic from 'next/dynamic';
import * as React from 'react';

import { GlobalModalContent } from '../../hocs/withGlobalModal';

const ModalVerifyPhone = dynamic(
  () => import('./components/ModalVerifyPhone').then((mod) => mod.ModalVerifyPhone),
  {
    ssr: false,
  },
);

export const verifyPhoneModalContent: GlobalModalContent = {
  content: (props) => <ModalVerifyPhone {...props} />,
  title: null,
  closable: false,
  width: 410,
  modalLess: true,
};


