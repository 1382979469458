import '@doltech/ui/lib/antd/dol.theme.global.less';
import '@doltech/ui/lib/antd/landing.theme.global.less';
import 'nprogress/nprogress.css';

import { withAppScroll } from '@doltech/core/lib/appScroll/withAppScroll';
import { bootstrapAuthManager } from '@doltech/core/lib/auth/bootstrapAuthManager';
import { withGlobalUserAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { withEncryptedData } from '@doltech/core/lib/encrypt-page-data/withEncryptedData';
import { environment } from '@doltech/core/lib/environment/environment';
import { withGTM } from '@doltech/core/lib/hooks/gtm/withGTM';
import { withGTMPageContext } from '@doltech/core/lib/hooks/gtm/withGTMPageContext';
import { withReactQueryConfig } from '@doltech/core/lib/hooks/withReactQueryConfig';
import { withUserActivity } from '@doltech/modules/lib/management/Activity/UserActivity';
import { withUserAuthentication } from '@doltech/ui/lib/figma/Auth/hocs/withUserAuthentication';
import { withDolSystemDesignThemeProvider } from '@doltech/ui/lib/figma/Common/theme';
import { withLandingSharedLayout } from '@doltech/ui/lib/figma/Layout/Landing/hocs/withLandingSharedLayout';
import { withControlledAudioPlayInBackground } from '@doltech/ui/lib/hooks/controlled-audio-play-in-background.store';
import {
  useTrackingLastUrlAndAutoScrollToTop,
  withDolSetup,
} from '@doltech/ui/lib/hocs/withDolSetup';
import { withGlobalModal } from '@doltech/ui/lib/hocs/withGlobalModal';
import { withUpdateViewCount } from '@doltech/ui/lib/hocs/withUpdateViewCount';
import { compose } from '@doltech/utils/lib/compose';
import { initNumeralConfig } from '@doltech/utils/lib/numeral-config';
import { branch } from '@doltech/utils/lib/branch';
import { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import * as React from 'react';
import { withCustomerIO } from '@doltech/core/lib/hooks/withCustomerIO';
import { withSimpleAudioPlayInBackground } from '@doltech/ui/lib/hooks/simple-audio-play-in-background.store';
import { withGlobalModalPortal } from '@doltech/ui/lib/hocs/withGlobalModalPortal';

if (environment.REACT_APP_ENV !== 'PROD') {
  import('mobx').then((mod) =>
    mod.configure({
      enforceActions: 'observed',
      computedRequiresReaction: false,
      reactionRequiresObservable: false,
    })
  );
}

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
});
// trigger build
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
initNumeralConfig();
bootstrapAuthManager('landing');

interface CustomAppProps extends Omit<AppProps, 'Component'> {
  err: any;
  Component: any;
}

function CustomApp({ Component, pageProps }: CustomAppProps) {
  useTrackingLastUrlAndAutoScrollToTop();
  return <Component {...pageProps} />;
}

export default compose(
  withEncryptedData,
  withCustomerIO({
    siteId: environment.REACT_APP_CUSTOMER_IO_SITE_ID,
    env: [environment.REACT_APP_ENV],
  }),
  branch(
    () => environment.production,
    withGTM({
      gtmId: 'GTM-PRRZTQ6',
      env: ['PROD'],
    }),
    withGTM({
      gtmId: 'GTM-K7J89CZ',
      env: [''],
    })
  ),
  withReactQueryConfig,
  withDolSystemDesignThemeProvider,
  withAppScroll({
    getAppScrollElement: () => window,
    getAppContainer: () => document.getElementById('app-root'),
  }),
  withGlobalUserAuthInfo,
  withGlobalModal,
  withUserAuthentication({}, true),
  withUserActivity,
  withGTMPageContext({ showMessengerButton: false }),
  withUpdateViewCount,
  withLandingSharedLayout,
  withSimpleAudioPlayInBackground,
  withControlledAudioPlayInBackground,
  withDolSetup({}),
  withGlobalModalPortal
)(CustomApp);
