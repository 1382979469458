import React from 'react';

const identity = (Component) => Component;

const createFactory = (type) => {
  const factory = React.createElement.bind(null, type);

  factory.type = type;
  return factory;
};

export const branch =
  (test, left, right = identity) =>
  (BaseComponent) => {
    let leftFactory;
    let rightFactory;
    return (props) => {
      if (test(props)) {
        leftFactory = leftFactory || createFactory(left(BaseComponent));
        return leftFactory(props);
      }
      rightFactory = rightFactory || createFactory(right(BaseComponent));
      return rightFactory(props);
    };
  };
